
import { Component, Vue } from 'vue-property-decorator';
import { mapState } from 'pinia';
import useFormsStore from '@/stores/forms/forms';
import { formatRelativeDate, getUTCDate } from '@/utils/index.js';
import ToolbarActions from './components/table/toolbar-actions.vue';
import DataTable from './components/table/data-table.vue';
import {
  Form, FormRow, FormStatus, FormColumn, FormAction,
} from './types';
import StatusColumn from './components/table/status-column.vue';
import ModifiedColumn from './components/table/modified-column.vue';
import SubmissionsColumn from './components/table/submissions-column.vue';
import NameColumn from './components/table/name-column.vue';
import Loader from './components/loader.vue';

@Component({
  components: {
    DataTable,
    ToolbarActions,
    Loader,
  },
  computed: {
    ...mapState(useFormsStore, ['forms']),
  },
})
export default class FormsList extends Vue {
  readonly forms: Form[];

  readonly formsStore = useFormsStore();

  private selectedIds: string[] = [];

  private isTableLoading = true;

  get toolbarActions() {
    if (!this.selectedRows.length) return [];
    const toolbarActions: FormAction[] = [
      {
        id: '0',
        icon: ['fal', 'arrow-up-right-from-square'],
        title: 'Open',
        isMultiSelected: false,
        handler: () => {
          this.selectedRows[0].open();
        },
        visible: this.selectedRows[0].status !== FormStatus[FormStatus.Archived],
      },
      {
        id: '1',
        icon: ['fal', 'pencil'],
        title: 'Edit',
        isMultiSelected: false,
        handler: () => {
          this.selectedRows[0].edit();
        },
        visible:
          this.selectedRows[0].status !== FormStatus[FormStatus.Archived]
          && this.selectedRows[0].type === 1,
      },
      {
        id: '2',
        icon: ['fal', 'box-archive'],
        title: 'Archive',
        isMultiSelected: true,
        handler: async () => {
          await Promise.all(this.selectedRows.map((row) => row.archive()));
        },
        visible: Boolean(
          this.selectedRows.find((item) => item.status !== FormStatus[FormStatus.Archived]),
        ),
      },
      {
        id: '3',
        icon: ['fal', 'box-open'],
        title: 'Activate',
        isMultiSelected: true,
        handler: async () => {
          await Promise.all(this.selectedRows.map((row) => row.activate()));
        },
        visible: Boolean(
          this.selectedRows.every((item) => item.status === FormStatus[FormStatus.Archived]),
        ),
      },
    ];

    if (this.selectedRows.length === 1) {
      return toolbarActions.filter((action) => action.visible);
    }
    return toolbarActions.filter((action: FormAction) => action.isMultiSelected && action.visible);
  }

  private confirmDeletion(hasMultipleSelectedForms = false) {
    return hasMultipleSelectedForms
      ? window.confirm(
        'The selected forms will be deleted along with all their submissions. Continue?',
      )
      : window.confirm('The form will be deleted along with all its submissions. Continue?');
  }

  get tableData(): FormRow[] {
    if (!this.forms) return [];

    return this.forms.map((form) => {
      const row: FormRow = {
        id: form.id,
        name: {
          title: form.name,
          link: { name: 'Forms_Forms_Submissions', params: { formId: form.id } },
        },
        submissions: {
          total: form.totalSubmissionsCount,
          unread: form.unreadSubmissionsCount,
        },
        modified: {
          date: getUTCDate(form.modified),
          textDate: formatRelativeDate(getUTCDate(form.modified)),
        },
        status: FormStatus[form.status],
        type: form.type,
        open: () => {
          window.open(form.url);
        },
        edit: () => {
          const url = form.type === 1 && `designer/${form.id}`;
          if (url) window.open(url, '_self');
        },
        archive: async () => {
          try {
            await this.formsStore.archiveForm(form);
          } catch (error) {
            throw new Error("Can't archive form.");
          }
        },
        delete: async (confirmationDisabled = false) => {
          if (!confirmationDisabled && !this.confirmDeletion()) {
            return;
          }

          await this.formsStore.deleteForm(form);
          this.selectedIds = this.selectedIds.filter((id) => id !== form.id);
        },
        copy: () => {
          this.copyWidget(form.id);
        },
        activate: async () => {
          try {
            await this.formsStore.activateForm(form);
          } catch (error) {
            throw new Error("Can't activate form.");
          }
        },
      };
      return row;
    });
  }

  private async copyWidget(id: string) {
    const widget = await this.formsStore.getFormWidget(id);

    const tempInput = document.createElement('input');
    tempInput.value = widget;
    document.body.appendChild(tempInput);
    tempInput.select();

    try {
      document.execCommand('copy');
    } catch (err) {
      alert('Unable to copy.');
    }

    document.body.removeChild(tempInput);
  }

  private get FormStatus() {
    return FormStatus;
  }

  async created() {
    try {
      await this.formsStore.loadForms();
    } catch (error) {
      throw new Error("Can't load forms list");
    }
    this.isTableLoading = false;
  }

  private selectionChanged(value: string[]) {
    this.selectedIds = value;
  }

  get selectedRows(): FormRow[] {
    return this.tableData.filter((f) => this.selectedIds.indexOf(f.id) !== -1);
  }

  get columns(): FormColumn[] {
    return [
      {
        id: '0',
        component: NameColumn,
        field: 'name',
        label: 'Name',
        customSort: (a: FormRow, b: FormRow, isAsc: boolean) => {
          if (isAsc) return a.name.title.localeCompare(b.name.title);
          return b.name.title.localeCompare(a.name.title);
        },
        actions: [
          {
            id: '0',
            icon: ['fal', 'arrow-up-right-from-square'],
            handler(row: FormRow) {
              row.open();
            },
            text: 'Open',
            visible(row: FormRow) {
              return row.status !== FormStatus[FormStatus.Archived];
            },
          },
          {
            id: '1',
            icon: ['fal', 'pencil'],
            handler(row) {
              row.edit();
            },
            text: 'Edit',
            visible(row: FormRow) {
              return row.status !== FormStatus[FormStatus.Archived] && row.type === 1;
            },
          },
        ],
        contextMenuActions: [
          {
            id: '0',
            icon: ['fal', 'copy'],
            handler(row: FormRow) {
              row.copy();
            },
            text: 'Copy widget',
            visible(row: FormRow) {
              return row.status !== FormStatus[FormStatus.Archived];
            },
          },
          {
            id: '1',
            icon: ['fal', 'box-open'],
            async handler(row: FormRow) {
              await row.activate();
            },
            text: 'Activate',
            visible(row: FormRow) {
              return row.status === FormStatus[FormStatus.Archived];
            },
          },
          {
            id: '2',
            icon: ['fal', 'box-archive'],
            async handler(row: FormRow) {
              await row.archive();
            },
            text: 'Archive',
            visible(row: FormRow) {
              return row.status !== FormStatus[FormStatus.Archived];
            },
          },
          {
            id: '3',
            icon: ['fal', 'trash-can'],
            async handler(row: FormRow) {
              await row.delete();
            },
            text: 'Delete',
            visible() {
              return true;
            },
          },
        ],
      },
      {
        id: '1',
        component: SubmissionsColumn,
        field: 'submissions',
        label: 'Submissions',
        customSort: (a: FormRow, b: FormRow, isAsc: boolean) => {
          const property = a.submissions.total - b.submissions.total === 0 ? 'unread' : 'total';

          if (isAsc) return a.submissions[property] - b.submissions[property];
          return b.submissions[property] - a.submissions[property];
        },
      },
      {
        id: '2',
        component: ModifiedColumn,
        field: 'modified',
        label: 'Modified',
        customSort: (a: FormRow, b: FormRow, isAsc: boolean) => {
          const aModifiedDate = a.modified.date as unknown as number;
          const bModifiedDate = b.modified.date as unknown as number;

          if (isAsc) return aModifiedDate - bModifiedDate;
          return bModifiedDate - aModifiedDate;
        },
      },
      {
        id: '3',
        component: StatusColumn,
        field: 'status',
        label: 'Status',
        customSort: (a: FormRow, b: FormRow, isAsc: boolean) => {
          if (isAsc) return a.status.localeCompare(b.status);
          return b.status.localeCompare(a.status);
        },
      },
    ];
  }

  get hasXmlForms() {
    return this.forms.some((form) => form.type === 0);
  }
}
