<template>
  <router-view v-if="formId" />
  <forms-list v-else />
</template>

<script>
import FormsList from './forms-list.vue';

export default {
  name: 'FormsPage',
  components: { FormsList },
  computed: {
    formId() {
      return this.$route.params.formId;
    },
  },
};
</script>
