
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnProps } from './types';

@Component
export default class SubmissionsColumn extends Vue {
  @Prop({ required: true })
  private readonly props: ColumnProps;

  get title() {
    let text = `Submissions: ${this.props.row.submissions.total}`;
    if (this.props.row.submissions.unread > 0) {
      text += `, New: ${this.props.row.submissions.unread}`;
    }
    return text;
  }
}
