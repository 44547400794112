
import { Component, Prop, Vue } from 'vue-property-decorator';
import { ColumnProps } from './types';

@Component
export default class ModifiedColumn extends Vue {
  @Prop({ required: true })
  private readonly props: ColumnProps;

  get dateTime() {
    return new Date(this.props.row.modified.date).toISOString();
  }
}
